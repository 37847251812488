import React from "react"
import { useApp } from "../../hooks/useApp"

export const withStore = Component => ({ name = "Store", page, template }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { _id, title, address, addressRich, description, email, location, openhours, phone, specialDescription, specialTradingHours, footerContent } =
    page || {}
  const {
    additionalAddressLabelText,
    additionalPhoneLabelText,
    additionalEmailLabelText,
    additionalOpeningHoursLabelText,
    additionalBackToStoresButtonText,
    additionalSpecialOpeningHoursButtonText,
  } = template || {}

  Component.displayName = name
  return (
    <Component
      title={title}
      address={address}
      addressRich={addressRich}
      description={description}
      phone={phone}
      email={email}
      openhours={openhours}
      location={location}
      backUrl={routes["STORE"]}
      store={{ ...page, id: _id }}
      specialDescription={specialDescription}
      specialTradingHours={specialTradingHours}
      footerContent={footerContent}
      additionalAddressLabelText={additionalAddressLabelText}
      additionalPhoneLabelText={additionalPhoneLabelText}
      additionalEmailLabelText={additionalEmailLabelText}
      additionalOpeningHoursLabelText={additionalOpeningHoursLabelText}
      additionalBackToStoresButtonText={additionalBackToStoresButtonText}
      additionalSpecialOpeningHoursButtonText={additionalSpecialOpeningHoursButtonText}
    />
  )
}
